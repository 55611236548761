//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import i18n from '@/vueI18n';
// import { getLanguageCode } from '@/i18n';
import moment from 'moment';import Roles from '@/security/roles';

import IamViewToolbar from '@/modules/iam/components/iam-view-toolbar.vue';

const { fields } = UserModel;

export default {
  name: 'app-iam-view-page',

  props: ['id'],

  components: {
    [IamViewToolbar.name]: IamViewToolbar,
    // [ProfileFormPage.name]: ProfileFormPage,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'iam/view/record',
      loading: 'iam/view/loading',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale
    },

    fields() {
      return fields;
    },
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.users') +
            ' | ' +
            this.i18n('iam.view.title') 
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'iam/view/doFind',
      // doSetSelectedRows:'iam/list/doSetSelectedRows'
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(record, fieldName) {
      const val = UserModel.presenter(record, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(record, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == record[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(record, fieldName) {
      const val = UserModel.presenter(record, fieldName);
      return val[this.language] ? val[this.language] : 'ـــ'; 
    },
    presenterDateTime(record, fieldName) {
      const val = UserModel.presenter(record, fieldName);
      return val ? moment(val).locale(this.language).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(record, fieldName) {
      const val = UserModel.presenter(record, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(record, fieldName) {
      return moment(UserModel.presenter(record, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(record, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = UserModel.presenter(record, fieldName)
      let n = record[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.language).format('hh:mm a')
    },

    roleDescriptionOf(roleId) {
      return Roles.descriptionOf(roleId);
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
  },
};
